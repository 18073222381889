<div class="home__content profile">
  <div class="profile__nav">
    <ul class="profile__nav--list">
      <li class="profile__nav--item" routerLinkActive='active'>
        <a class="profile__nav--link" routerLink='perfil'>
          <svg class="profile__nav--svg">
            <use xlink:href='#user_folder'>
              <symbol viewBox="0 0 16.88 15.412">
                <path id="user_folder"
                  d="M3.1,4A1.1,1.1,0,0,0,2,5.1V7.3H18.88V6.569a1.1,1.1,0,0,0-1.1-1.1l-9.891,0a2.055,2.055,0,0,1-.428-.555C7.178,4.465,6.887,4,6.4,4ZM2.367,8.037A.367.367,0,0,0,2,8.4v9.908a1.1,1.1,0,0,0,1.1,1.1H17.779a1.1,1.1,0,0,0,1.1-1.1V8.4a.367.367,0,0,0-.367-.367Zm8.606,1.1a.183.183,0,0,1,.163.1l.136.275a1.335,1.335,0,0,1,.869.4,1.9,1.9,0,0,1,.495,1.347,2.583,2.583,0,0,1-.184.918.655.655,0,0,1,.19.571.853.853,0,0,1-.573.762,2.14,2.14,0,0,1-.488.96l-.04.139v.712c.138.318.566.441,1.061.584.8.231,1.8.519,1.874,1.846a.185.185,0,0,1-.049.137.182.182,0,0,1-.133.057H6.587A.183.183,0,0,1,6.4,17.75c.078-1.327,1.074-1.614,1.874-1.846.494-.143.923-.266,1.061-.584v-.81a2.088,2.088,0,0,1-.528-1,.839.839,0,0,1-.571-.742c0-.353.078-.513.188-.6a2.956,2.956,0,0,1-.178-1A1.813,1.813,0,0,1,8.765,9.84,2.969,2.969,0,0,1,10.973,9.137Z"
                  transform="translate(-2 -4)" />
              </symbol>
            </use>
          </svg>
          Meu Perfil
        </a>
      </li>

      <li class="profile__nav--item" routerLinkActive='active'>
        <a class="profile__nav--link" routerLink='meus-cursos'>
          <svg class="profile__nav--svg">
            <use xlink:href="#class">
              <symbol viewBox="0 0 17.88 13.066">
                <path id="class"
                  d="M1.375,4a.649.649,0,0,0-.688.688v9.628H4.814V12.94H8.94l.408.688-.408.688h8.252V4.688A.649.649,0,0,0,16.5,4ZM13.8,6.214a.634.634,0,0,1,.365.193.61.61,0,0,1-.065.752L10.659,9.91a.408.408,0,0,1-.473.064L6.877,8.685,4.341,9.91a.262.262,0,0,1-.215.064.658.658,0,0,1-.473-.258.531.531,0,0,1,.193-.688L6.6,7.653a.424.424,0,0,1,.408,0l3.181,1.225L13.41,6.342A.473.473,0,0,1,13.8,6.214ZM.752,15A.754.754,0,0,0,0,15.755v1.311H17.88V15.755A.754.754,0,0,0,17.128,15Z"
                  transform="translate(0 -4)" />
              </symbol>
            </use>
          </svg>
          Meus Cursos
        </a>
      </li>

      <li class="profile__nav--item" routerLinkActive='active'>
        <a class="profile__nav--link" routerLink='agenda'>
          <svg class="profile__nav--svg" viewBox="0 0 28.243 29.471">
            <path id="calendar"
              d="M8.14,0A1.237,1.237,0,0,0,6.912,1.228V2.456H3.228A1.187,1.187,0,0,0,2,3.684v4.3H30.243v-4.3a1.187,1.187,0,0,0-1.228-1.228H25.331V1.228A1.237,1.237,0,0,0,24.1,0H22.875a1.237,1.237,0,0,0-1.228,1.228V2.456H10.6V1.228A1.237,1.237,0,0,0,9.368,0Zm0,1.228H9.368V4.912H8.14Zm14.736,0H24.1V4.912H22.875ZM2,9.21V28.243a1.187,1.187,0,0,0,1.228,1.228H29.015a1.187,1.187,0,0,0,1.228-1.228V9.21Zm6.14,3.684h3.07v3.07H8.14Zm4.3,0h3.07v3.07h-3.07Zm4.3,0h3.07v3.07h-3.07Zm4.3,0H24.1v3.07h-3.07ZM8.14,17.191h3.07v3.07H8.14Zm4.3,0h3.07v3.07h-3.07Zm4.3,0h3.07v3.07h-3.07Zm4.3,0H24.1v3.07h-3.07ZM8.14,21.489h3.07v3.07H8.14Zm4.3,0h3.07v3.07h-3.07Zm4.3,0h3.07v3.07h-3.07Zm4.3,0H24.1v3.07h-3.07Z"
              transform="translate(-2)" />
          </svg>
          Aulas Marcadas
        </a>
      </li>

      <li class="profile__nav--item" routerLinkActive='active'>
        <a class="profile__nav--link" routerLink='convite'>
          <svg class="profile__nav--svg">
            <use xlink:href="#settings">
              <symbol xmlns="http://www.w3.org/2000/svg" width="13.391" height="13.664" viewBox="0 0 13.391 13.664">
                <path id="settings"
                  d="M14.293,9.72a4.2,4.2,0,0,0,.068-.888,4.2,4.2,0,0,0-.068-.888l1.3-1.161L14.019,4.05,12.38,4.6a6.7,6.7,0,0,0-1.5-.888L10.467,2H7.324L6.982,3.708A5.865,5.865,0,0,0,5.411,4.6L3.771,4.05,2.2,6.782,3.5,7.944a4.2,4.2,0,0,0-.068.888A4.2,4.2,0,0,0,3.5,9.72L2.2,10.882l1.571,2.733,1.64-.547h0a6.7,6.7,0,0,0,1.5.888l.41,1.708h3.211l.342-1.708a4.177,4.177,0,0,0,1.5-.888h0l1.64.547,1.571-2.733-1.3-1.161ZM8.9,11.565a2.733,2.733,0,1,1,2.733-2.733A2.741,2.741,0,0,1,8.9,11.565Z"
                  transform="translate(-2.2 -2)" />
              </symbol>
            </use>
          </svg>
          Convide amigos
        </a>
      </li>

      <li class="profile__nav--item" routerLinkActive='active'>
        <a class="profile__nav--link" routerLink='senha'>
          <svg class="profile__nav--svg">
            <use xlink:href="#password">
              <symbol viewBox="0 0 15.061 19.579">
                <path id="password"
                  d="M10.53,0A4.508,4.508,0,0,0,6.012,4.518V7.53A3.022,3.022,0,0,0,3,10.542v6.024a3.022,3.022,0,0,0,3.012,3.012h9.036a3.022,3.022,0,0,0,3.012-3.012V10.542A3.022,3.022,0,0,0,15.048,7.53V4.518A4.508,4.508,0,0,0,10.53,0Zm0,1.506a2.949,2.949,0,0,1,3.012,3.012V7.53H7.518V4.518A2.949,2.949,0,0,1,10.53,1.506Zm0,9.789A1.509,1.509,0,0,1,12.036,12.8a1.442,1.442,0,0,1-.753,1.271v1.741a.753.753,0,0,1-1.506,0V14.072A1.442,1.442,0,0,1,9.024,12.8,1.509,1.509,0,0,1,10.53,11.3Z"
                  transform="translate(-3)" />
              </symbol>

            </use>
          </svg>
          Mudar a senha
        </a>
      </li>

      <li class="profile__nav--item" routerLinkActive='active'>
        <a class="profile__nav--link">
          <svg class="profile__nav--svg">
            <use xlink:href="#card_payment">
              <symbol viewBox="0 0 15.145 10.096">
                <path id="card_payment"
                  d="M6.058,5a1.009,1.009,0,0,0-1.01,1.01h10.1A1.009,1.009,0,0,0,14.135,5ZM5.048,6.01H4.907a4.229,4.229,0,0,0-2.794.754C1.52,7.281,0,8.57,0,8.57v1.3S2.172,8.053,2.778,7.523a3.47,3.47,0,0,1,2.13-.5h.141Zm0,1.01v3.864L7.994,9.229H8a1.008,1.008,0,1,1,1.149,1.657L7.611,12.068l-1.8,1.389a3.028,3.028,0,0,1-1.848.629H0V15.1H3.96a4.058,4.058,0,0,0,2.464-.839l2.843-2.189h4.869a1.009,1.009,0,0,0,1.01-1.01V7.019Z"
                  transform="translate(0 -5)" />
              </symbol>

            </use>
          </svg>
          Formas de pagamento
        </a>
      </li>

      <li class="profile__nav--item" routerLinkActive='active'>
        <a class="profile__nav--link">
          <svg class="profile__nav--svg">
            <use xlink:href="#remove_administrator">
              <symbol viewBox="0 0 15.531 15.513">
                <path id="remove_administrator"
                  d="M8.42,2A3.242,3.242,0,0,0,5.765,3.071c-.587.773-.689,1.924-.313,3.517a1.159,1.159,0,0,0-.222.821,1.35,1.35,0,0,0,.578,1.1A3.229,3.229,0,0,0,6.52,10c0,.072,0,.141,0,.211a6.208,6.208,0,0,1-.03.92,1,1,0,0,1-.381.442L7.129,12.8l.949-1.179a.367.367,0,0,1,.348-.167c.122-.038.263.061.348.167l.215.266a1.7,1.7,0,0,1,.186.231l.62.75a3.919,3.919,0,0,0-.076.765h0a.327.327,0,0,1-.25-.121l-.641-.8-.237.208a6.337,6.337,0,0,1,.732,2H7.534a6.644,6.644,0,0,1,.731-2l-.245-.2-.634.788a.328.328,0,0,1-.25.121h0a.326.326,0,0,1-.249-.116l-1.353-1.63c-.2.088-.408.173-.63.261-1.261.5-2.83,1.121-2.939,3.083l-.018.341h8.3a3.881,3.881,0,1,0,.23-4.227.764.764,0,0,1-.122-.188c-.019-.391-.052-1.018-.052-1.159A3.509,3.509,0,0,0,11,8.526c.232-.11.524-.4.591-1.134a1.113,1.113,0,0,0-.207-.8,4.2,4.2,0,0,0-.02-3.138A1.813,1.813,0,0,0,10.1,2.609,2.082,2.082,0,0,0,8.42,2Zm.006.646c.611,0,1.052.154,1.207.422l.08.135.154.021a1.22,1.22,0,0,1,.944.561,3.761,3.761,0,0,1-.1,2.791l-.1.266.256.129a.435.435,0,0,1,.087.364c-.044.478-.2.606-.228.612h-.279l-.04.278a2.846,2.846,0,0,1-.583,1.348l-.163.092v.188c0,.335,0,.608.01.913l-1.238.688-1.257-.7c.006-.191,0-.371,0-.554,0-.111,0-.226,0-.35l-.02-.182-.138-.1a2.526,2.526,0,0,1-.586-1.336l-.005-.29H6.124c-.047-.018-.191-.17-.252-.611a.446.446,0,0,1,.116-.383l.192-.127-.057-.221C5.747,5.125,5.8,4.1,6.281,3.462A2.641,2.641,0,0,1,8.427,2.646ZM13.6,10.4a3.232,3.232,0,1,1-3.232,3.232A3.232,3.232,0,0,1,13.6,10.4Zm-1.939,2.909v.646h3.878v-.646Z"
                  transform="translate(-1.945 -2)" />
              </symbol>
            </use>
          </svg>
          Encerrar conta
        </a>
      </li>
      <li class="profile__nav--item" (click)="logout()">
        <a class="profile__nav--link">
          <svg class="profile__nav--svg">
            <use xlink:href="#logout">
              <symbol id="logout" viewBox="0 0 500 500">
                <path
                  d="M24.96875 1.96875C18.17279 1.96875 12.052876 4.9382251 7.8476562 9.6347656 A 2.0002 2.0002 0 1 0 10.826172 12.302734C14.306952 8.4152753 19.33471 5.96875 24.96875 5.96875C35.485874 5.96875 43.96875 14.451626 43.96875 24.96875C43.96875 35.485874 35.485874 43.96875 24.96875 43.96875C19.335804 43.96875 14.308056 41.522447 10.826172 37.634766 A 2.0002 2.0002 0 1 0 7.8476562 40.302734C12.053772 44.999053 18.173696 47.96875 24.96875 47.96875C37.647626 47.96875 47.96875 37.647626 47.96875 24.96875C47.96875 12.289874 37.647626 1.96875 24.96875 1.96875 z M 10.992188 15.949219 A 2.0002 2.0002 0 0 0 9.6171875 16.554688L2.7949219 23.376953 A 2.0002 2.0002 0 0 0 2.8027344 26.568359L9.6171875 33.382812 A 2.0002 2.0002 0 1 0 12.445312 30.554688L8.859375 26.96875L27.03125 26.96875 A 2.0002 2.0002 0 1 0 27.03125 22.96875L8.859375 22.96875L12.445312 19.382812 A 2.0002 2.0002 0 0 0 10.992188 15.949219 z" />
              </symbol>
            </use>
          </svg>
          Sair
        </a>
      </li>
    </ul>
  </div>

  <div class="profile__content">
    <router-outlet></router-outlet>
  </div>
</div>
