<div class="invite__banner">
  <div class="invite__text">
    <h1 class="title-1">Minhas aulas marcadas</h1>
    <p class="paragraph">Todas aulas agendadas estão listadas abaixo.</p>
  </div>
  <img src="/assets/img/online lessons.svg">
</div>

<div class="invite">
  <div class="invite__table">
    <h2 class="title-2">Aulas marcadas</h2>

    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>

        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td mat-cell *matCellDef="let row; let id = index"> {{id+1}} </td>
        </ng-container>

        <!-- Progress Column -->
        <ng-container matColumnDef="curso">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Curso </th>
          <td mat-cell *matCellDef="let row"> {{row.nome}} </td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="horario">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Horario </th>
          <td mat-cell *matCellDef="let row"> {{row.horario}} </td>
        </ng-container>


        <ng-container matColumnDef="estado">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> estado </th>

          <td mat-cell *matCellDef="let row" class="{{row.estado}}">{{row.estado}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">Usuário não encontrado "{{input.value}}"</td>
        </tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>

  </div>
  <div class="invite__content">
    <h2 class="title-2">Sumario</h2>

    <div class="invite__content--details">
      <p class="invite__content--number">{{totalConf}}</p>
      <p class="invite__content--description">Total Confirmadas</p>
    </div>
    <div class="invite__content--details">
      <p class="invite__content--number">{{totalProc}}</p>
      <p class="invite__content--description">Total Processando</p>
    </div>
    <div class="invite__content--details">
      <p class="invite__content--number big">{{total}}</p>
      <p class="invite__content--description">Numero total de aulas marcadas</p>
    </div>
  </div>
</div>
