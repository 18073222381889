export class Instrutor{
  nome: string
	publico_alvo: string
	categoria: string
  duracao: string
	requisitos: string
  objectivos: string
  descricao: string
  email: string
}
