<div class="home__content mb-0">
  <div class="business v8">
    <div class="business__header">
      <div class="business__header--text">
        <h2 class="title-1">Faça parte da <span>nossa equipe</span></h2>
        <p class="paragraph">Assim como os alunos online, os professores online também desfrutam da flexibilidade e
          conveniência do meio. Eles podem ensinar no conforto de suas próprias casas e não precisam estar em um local
          específico em um horário específico para ensinar ou interagir com seus alunos.</p>
        <button class="btn btn--main">Fale connosco</button>
      </div>
      <div class="business__header--img">
        <img src="/assets/img/teach.png" alt="imagens">
      </div>
    </div>
    <div class="business__body">
      <div class="business__body--text">
        <ul class="business__body--list">
          <li class="business__body--item"><img src="/assets/img/check.svg" alt="check">A recessão econômica, as
            demissões, as medidas de bloqueio e o risco de doenças causados ​​pelo COVID-19 tornam os empregos autónomos
            remotos, como o ensino online, muito atraentes.</li>
          <li class="business__body--item"><img src="/assets/img/check.svg" alt="check">Ensine alunos de todo o
            Moçambique.</li>
          <li class="business__body--item"><img src="/assets/img/check.svg" alt="check">Monetize seu conhecimento.</li>
          <li class="business__body--item"><img src="/assets/img/check.svg" alt="check">Satisfaça a sua paixão por se
            tornar um professor online.</li>
          <li class="business__body--item"><img src="/assets/img/check.svg" alter="check">A tecnologia pode tornar as
            aulas mais envolventes</li>
        </ul>

      </div>
      <div class="business__body--content">

        <router-outlet></router-outlet>

      </div>
    </div>
  </div>
</div>

<div class="overflow-hidden">
  <div class="rotated white">
    <div class="home__content">
      <h2 class="title-1 text-center color-white">Empresas que <span>colaboram <br></span> connosco</h2>
      <div class="partners white">
        <img src="/assets/img/addpp white.png" alt="addpp">
        <img src="/assets/img/avsi white.png" alt="avsi">
        <img src="/assets/img/bnm white.png" alt="bnm">
        <img src="/assets/img/in white.png" alt="in">
        <img src="/assets/img/terre white.png" alt="terre">
        <img src="/assets/img/logo white.png" alt="logo">
        <img src="/assets/img/ez.png" alt="ez-logo">
      </div>
    </div>
  </div>
</div>
