<nav class="navbar {{headerColor}}">
  <ng-container *ngIf="headerColor !=='english'; else showEnglish">
    <div class="navbar--logo">
      <img routerLink='/' src="/assets/img/icon.png" alt="logo">
    </div>
  </ng-container>
  <ng-template #showEnglish>
    <div class="navbar--logo english">
      <img routerLink='/' src="/assets/img/UEA-BRANCO.png" alt="logo">
    </div>
  </ng-template>

  <button class="hamburger hamburger--slider {{headerColor}}" #hamburger (click)="toggleClass()" type="button">
    <span class="hamburger-box">
      <span class="hamburger-inner"></span>
    </span>
  </button>
  <ul class="navbar--list {{headerColor}}">
    <div *ngIf="isLogged===false;">
      <li class="navbar--item hideBig" (click)="toggleClass()">
        <a class="navbar--link {{headerColor}}" routerLinkActive='active' href="https://unitec.ac.mz/registro"
          (click)="changeHeader('')">
          <button class="btn btn--main">Registar</button>
        </a>
      </li>
    </div>
    <li class="navbar--item" (click)="toggleClass()">
      <a class="navbar--link {{headerColor}}" routerLinkActive='active' href="https://unitec.ac.mz/"
        (click)="changeHeader('')">Início</a>
    </li>
    <li class="navbar--item" (click)="toggleClass()">
      <a class="navbar--link {{headerColor}}" routerLinkActive='active' href="https://unitec.ac.mz/cursos"
        (click)="changeHeader('')">Cursos</a>
    </li>
    <li class="navbar--item" (click)="toggleClass()">
      <a class="navbar--link {{headerColor}}" routerLinkActive='active' routerLink='cursos' routerLink='language'
        (click)="changeHeader('english')">Unitec Language Center</a>
    </li>
    <li class="navbar--item" (click)="toggleClass()">
      <a class="navbar--link {{headerColor}}" routerLink='unitec-business' routerLinkActive='active'
        (click)="changeHeader('')">Unitec Business</a>
    </li>
    <li class="navbar--item" (click)="toggleClass()">
      <a class="navbar--link {{headerColor}}" routerLink='marcar-aula' routerLinkActive='active'
        (click)="changeHeader('')">Marcar aula</a>
    </li>
    <li class="navbar--item" (click)="toggleClass()">
      <a class="navbar--link {{headerColor}}" routerLinkActive='active' routerLink='contacto'
        (click)="changeHeader('')">Contacto</a>
    </li>
  </ul>

  <div class="navbar__menu">
    <div class="navbar--btn" *ngIf="isLogged===false;else profile">
      <button class="btn btn--white {{headerColor}}" routerLink='login'>Entrar</button>
      <a class="btn btn--main {{headerColor}} hideSmall" onclick={handleClick} href="https://unitec.ac.mz/registro">Registar</a>
    </div>
    <ng-template #profile>
      <div class="navbar--profile" routerLink='/user'>
        Meu perfil
        <div class="navbar__menu--profile {{headerColor}}">
          <ng-container *ngIf="imgSrc === ''; else profilePicture">
            <svg>
              <use xlink:href="#good">
                <symbol id="good" viewBox="0 0 30 30">
                  <path
                    d="M18,19v-2c0.45-0.223,1.737-1.755,1.872-2.952c0.354-0.027,0.91-0.352,1.074-1.635c0.088-0.689-0.262-1.076-0.474-1.198 c0,0,0.528-1.003,0.528-2.214c0-2.428-0.953-4.5-3-4.5c0,0-0.711-1.5-3-1.5c-4.242,0-6,2.721-6,6c0,1.104,0.528,2.214,0.528,2.214 c-0.212,0.122-0.562,0.51-0.474,1.198c0.164,1.283,0.72,1.608,1.074,1.635C10.263,15.245,11.55,16.777,12,17v2c-1,3-9,1-9,8h24 C27,20,19,22,18,19z" />
                </symbol>
              </use>
            </svg>
          </ng-container>
          <ng-template #profilePicture>
            <img src="{{imgSrc}}">
          </ng-template>
        </div>
      </div>
    </ng-template>
  </div>
</nav>
