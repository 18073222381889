<div class="home__content v8">
  <div class="login">
    <h2 class="title-1 text-center">Não se preocupe</h2>
    <p class="paragrapth text-center">estamos aqui para ajudá-lo a recuperar sua senha. <br> Digite o endereço de e-mail
      que
      você usou quando se inscreveu e enviaremos instruções para redefinir sua senha!</p>
    <form [formGroup]="recuverForm" (ngSubmit)="onSubmit()" class="form">
      <div class="error" [ngClass]="{'d-none': error === false}">
        <p class="paragraph text-center">Email não encontrado!</p>
      </div>
      <div class="good" [ngClass]="{'d-none': good === false}">
        <p class="paragraph text-center">Por favor verifique o seu email!</p>
      </div>
      <label class="form__label" for="email">Endereço de e-mail</label>
      <div class="form__input--container">
        <img class="form__input--img" src="/assets/img/letter.svg" alt="Letter">
        <input class="form__input" formControlName="email" type="email" id="email"
          placeholder="insira o seu endereço de e-mail">
        <div class="error" *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">
          Email invalido!
        </div>
      </div>
      <button class="btn btn--main btn--hight" [disabled]="submitted">
        <ng-container *ngIf="!submitted; else showLoading">
          Enviar link de recuperação
        </ng-container>
        <ng-template #showLoading>
          <img src="/assets/img/pulse.gif" alt="">
        </ng-template>
      </button>
      <p class="paragraph">Não registado ainda? <span routerLink='/registo'>Crie a sua conta aqui</span></p>
    </form>
  </div>
</div>
