<div class="senha">
  <div class="senha__text">
    <h1 class="title-1">Mudar senha</h1>
  </div>
  <img src="/assets/img/password.svg">
</div>

<div class="senha__container">
  <form [formGroup]="mudarSenha" (ngSubmit)="onChange()">
    <div class="user__password">
      <!-- <label class="form__label" for="atual">senha atual</label>
      <div class="form__input--container">
        <div class='d-flex'>
          <input class="form__input" type="{{atualTipo}}" id="atual" formControlName="antiga" placeholder="Senha atual">
          <a (click)="toggle('atual')">
            <ng-container *ngIf="atualBool; else hideAtual">
              <img src="/assets/img/show.png">
            </ng-container>
            <ng-template #hideAtual>
              <img src="/assets/img/hide.png">
            </ng-template>
          </a>
        </div>
        <div *ngIf="submitted && s.senha.errors">
          <div class="error" *ngIf="s.senha.errors.required">Introduza a senha atual</div>
        </div>
      </div> -->
      <label class="form__label" for="nova">nova senha</label>
      <div class="form__input--container">
        <div class="d-flex">
          <input class="form__input" type="{{novaTipo}}" id="nova" formControlName="senha" placeholder="nova senha">
          <a (click)="toggle('nova')">
            <ng-container *ngIf="novaBool; else novaAtual">
              <img src="/assets/img/show.png">
            </ng-container>
            <ng-template #novaAtual>
              <img src="/assets/img/hide.png">
            </ng-template>
          </a>
        </div>
        <div *ngIf="submitted && s.senha.errors">
          <div class="error" *ngIf="s.senha.errors.required">Introduza a senha</div>
          <div class="error" *ngIf="s.senha.errors?.pattern">Senha invalida, a senha deve obedecer todos Requisitos
          </div>
        </div>
      </div>
      <label class="form__label" for="confirm">confirme a senha</label>
      <div class="form__input--container">
        <div class="d-flex">
          <input class="form__input" type="{{confirmTipo}}" id="confirm" formControlName="confirm"
            placeholder="confirme a senha">
          <a (click)="toggle('confirm')">
            <ng-container *ngIf="confirmBool; else hideConfirm">
              <img src="/assets/img/show.png">
            </ng-container>
            <ng-template #hideConfirm>
              <img src="/assets/img/hide.png">
            </ng-template>
          </a>
        </div>
        <div *ngIf="submitted && s.confirm.errors">
          <div class="error" *ngIf="s.confirm.errors.required">Introduza a senha</div>
          <div class="error" *ngIf="s.confirm.errors.mustMatch">as senhas não coincidem </div>
        </div>
      </div>
      <button class="btn btn--main">Alterar senha</button>
    </div>
  </form>
  <div class="senha__helper">
    <h2 class="title-2">Requisitos da senha</h2>
    <p class="paragraph"> <img src="/assets/img/check.svg"> Pelo menos 1 letra maiúscula</p>
    <p class="paragraph"> <img src="/assets/img/check.svg"> Pelo menos 1 numero</p>
    <p class="paragraph"> <img src="/assets/img/check.svg"> mínimo 6 dígitos</p>
  </div>
</div>
