<div class="invite__banner">
  <div class="invite__text">
    <h1 class="title-1">Convide amigos e ganhe 120 Mts por cada convidado</h1>
    <p class="paragraph">Cada amigo que aderir e se inscrever com o seu link receberá 10% de desconto e você receberá
      120 Mts de desconto na sua próxima inscrição. Você pode acumular esse desconto</p>
  </div>
  <img src="/assets/img/desconto.svg">
</div>

<div class="invite">
  <div class="invite__table">
    <h2 class="title-2">Lista de convidados</h2>

    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>

        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td mat-cell *matCellDef="let row; let id = index"> {{id+1}} </td>
        </ng-container>

        <!-- Progress Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
          <td mat-cell *matCellDef="let row"> {{row.email_aluno_conv}} </td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="estado">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
          <td mat-cell *matCellDef="let row"> {{row.estado}} </td>
        </ng-container>


        <ng-container matColumnDef="bonus">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Bonus </th>

          <td mat-cell *matCellDef="let row" class="{{row.estado}}">
            <ng-container *ngIf="row.estado==='Registado'; else elseTemplate">
              Aguardando
            </ng-container>
            <ng-template #elseTemplate>
              Depositado
            </ng-template>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">Usuário não encontrado "{{input.value}}"</td>
        </tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>

  </div>
  <div class="invite__content">
    <h2 class="title-2">Seu link de convite</h2>
    <ng-container *ngIf="showCode; else hideCode">
      <input type="text" class="invite__content--input" [(ngModel)]="inviteLink" disabled>
      <button class="btn btn--invite tooltip" [cdkCopyToClipboard]="inviteLink">copiar link
        <span class="tooltiptext">copiado</span>
      </button>
    </ng-container>
    <ng-template #hideCode>
      <input type="text" class="invite__content--input" disabled>
      <button class="btn btn--invite" (click)="gerarCodigo()">Gerar código</button>
    </ng-template>

    <div class="invite__content--details">
      <p class="invite__content--number">{{totalConv}}</p>
      <p class="invite__content--description">Total Convidados</p>
    </div>
    <div class="invite__content--details">
      <p class="invite__content--number">{{totalInscrito}}</p>
      <p class="invite__content--description">Convidados Inscritos</p>
    </div>
    <div class="invite__content--details">
      <p class="invite__content--number big">{{total_desconto}} Mts</p>
      <p class="invite__content--description">Desconto</p>
    </div>
  </div>
</div>
