<div class="home__content mb-0 v8">
  <div class="business">
    <div class="business__header">
      <div class="business__header--text">
        <h2 class="title-1">Qualifique a sua equipa <br> de <span>trabalho</span></h2>
        <p class="paragraph">Eleve a produtividade da sua organização com uma formação solida <br> para do
          desenvolvimento de competências.</p>
        <button class="btn btn--main">Fale connosco</button>
      </div>
      <div class="business__header--img">
        <img src="/assets/img/business.svg" alt="imagens">
      </div>
    </div>
    <div class="business__body">
      <div class="business__body--text">
        <ul class="business__body--list">
          <li class="business__body--item"><img src="/assets/img/check.svg" alt="check">Ajudar as empresas a reter
            talento.</li>
          <li class="business__body--item"><img src="/assets/img/check.svg" alt="check">Aumente a produtividade da sua
            empresa.</li>
          <li class="business__body--item"><img src="/assets/img/check.svg" alt="check">Prepara os seus colaboradores
            para o futuro e sucesso da sua empresa.</li>
          <li class="business__body--item"><img src="/assets/img/check.svg" alt="check">Aumente os seus
            índices de rentabilidade, qualidade e crescimento</li>
          <li class="business__body--item"><img src="/assets/img/check.svg" alter="check">Colaboradores capazes de
            suster vantagens competitivas</li>
        </ul>
      </div>
      <div class="business__body--content">
        <form [formGroup]="businessForm" (ngSubmit)="onSubmit()" class="form">
          <label class="form__label" for="nome">Nome</label>
          <div class="form__input--container">
            <input class="form__input" type="text" formControlName="nome" id="nome" placeholder="nome">
            <div class="error" *ngIf="f.nome.errors?.required && f.nome.touched">campo obrigatório</div>
          </div>

          <div class="form__group">
            <div class="w-47">
              <label class="form__label" for="email">Email</label>
              <div class="form__input--container">
                <input class="form__input" type="email" formControlName="email" id="email" placeholder="Email">
                <div class="error" *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">
                  Email invalido!
                </div>
              </div>
            </div>
            <div class="w-47">
              <label class="form__label" for="tel">Telefone</label>
              <div class="form__input--container">
                <input class="form__input" type="tel" formControlName="telefone" id="tel" placeholder="Telefone">
                <div class="error" *ngIf="f.telefone.errors?.required && f.telefone.touched">campo obrigatório</div>
              </div>
            </div>
          </div>

          <label class="form__label" for="empresa">Instituição / organização</label>
          <div class="form__input--container">
            <input class="form__input" type="text" formControlName="empresa" id="empresa"
              placeholder="Instituição / organização">
          </div>

          <label class="form__label" for="email">Mensagem</label>
          <div class="form__input--container">
            <textarea class="form__input" formControlName="mensagem"
              placeholder="Quais são as necessidades de treinamento da sua equipa"></textarea>
            <div class="error" *ngIf="f.mensagem.errors?.required && f.mensagem.touched">campo obrigatório</div>

          </div>

          <button class="btn btn--main btn--hight" [disabled]="submitted">
            <ng-container *ngIf="!submitted; else showLoading">
              Submeter
            </ng-container>
            <ng-template #showLoading>
              <img src="/assets/img/pulse.gif" alt="">
            </ng-template>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>


<div class="overflow-hidden">
  <div class="rotated white">
    <div class="home__content">
      <h2 class="title-1 text-center color-white">Empresas que <span>colaboram <br></span> connosco</h2>
      <div class="partners white">
        <img src="/assets/img/addpp white.png" alt="addpp">
        <img src="/assets/img/avsi white.png" alt="avsi">
        <img src="/assets/img/bnm white.png" alt="bnm">
        <img src="/assets/img/in white.png" alt="in">
        <img src="/assets/img/terre white.png" alt="terre">
        <img src="/assets/img/logo white.png" alt="logo">
        <img src="/assets/img/ez.png" alt="ez-logo">
      </div>
    </div>
  </div>
</div>
