export class Aluno {
  id: number;
  bi: string;
  dataemissao: String;
  datanasc: string;
  datavalidade: string;
  emailaluno: string;
  id_aluno: number;
  localemissao: string;
  nacionalidade: string;
  naturalidade: string;
  nivelformacao: string;
}
