<footer class="footer" #footerRef>
  <div class="footer--logo">
    <img src="/assets/img/unitec.png" alt="">
    <img src="/assets/img/UEA-BRANCO.png" alt="">
    <img src="/assets/img/uniconnect.png" alt="">
    <br>
    <p>&copy; Unitec Online 2024</p>
    <p>&copy; Universal Moçambique, Lda</p>
  </div>
  <ul class="footer--list">
    <li class="footer--item">
      <a class="footer--link"><img src="/assets/img/phone.svg" alt=""> <a href="tel:+258840111248">841088884</a> | <a
          href="tel:+258840111428">834303184</a> |
        <a href="tel:+258870088688">870088688</a> </a>
    </li>
    <li class="footer--item">
      <a href="mailto:info@unitec.ac.mz" class="footer--link"><img src="/assets/img/email.svg" alt="">
        info@unitec.ac.mz</a>
    </li>
    <li class="footer--item">
      <a class="footer--link" href="https://goo.gl/maps/Xkj7sheyf3Wb9iJw6" target="_blank"><img
          src="/assets/img/marker2.svg" alt=""> Av. Salvador Allende Nr.60, Maputo</a>
    </li>
    <li class="footer--item">
      <a class="footer--link"><img src="/assets/img/marker2.svg" alt=""> Av. Filipe Samuel Magaia Nr.552, Maputo</a>
    </li>
  </ul>

  <ul class="footer--list">
    <li class="footer--item">
      <a href="https://www.facebook.com/unitec.academy/" target="_blank" class="footer--link"> <img
          src="/assets/img/social-1.svg" alt=""> Facebook</a>
    </li>
    <li class="footer--item">
      <a href="https://twitter.com/OnlineUnitec" target="_blank" class="footer--link"> <img
          src="/assets/img/social-2.svg" alt=""> Twitter</a>
    </li>
    <li class="footer--item">
      <a href="https://www.instagram.com/unitec.academy/" target="_blank" class="footer--link"> <img
          src="/assets/img/social-3.svg" alt=""> Instagram</a>
    </li>
    <li class="footer--item">
      <a href="https://mz.linkedin.com/company/unitec-academy" target="_blank" class="footer--link"> <img
          src="/assets/img/linkedin.svg" alt=""> LinkedIn</a>
    </li>
  </ul>

  <div class="footer--subscribe">
    <p>Subscreva-se para receber promoções e novidades</p>

    <form>
      <input class="header--input" placeholder="email" type="text">
      <button class="header--search">Subscrever-se</button>
    </form>
  </div>
</footer>
